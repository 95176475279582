import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import CONFIG from "../config";
import useToast from "../hooks/useToast";
import useModal from "../hooks/useModal";
import useForceUpdate from "../hooks/useForceUpdate";
import { stateCodes } from "../utils/stateCodes";
import { validateRegistrationNumber } from "../utils/registrationNumberValidator";
import { carsData } from "../utils/commonCars";
import { getYears } from "../utils/yearsData";

const schema = yup.object().shape({
  stateCode: yup.string().required("*State code is required"),
  registrationNumber: yup.string().required("*Registration Number is required"),
  model: yup.string().required("*Model is required"),
  make: yup.string().required("*Make is required"),
  year: yup
    .number()
    .required("*Year is required")
    .default(2020)
    .positive("*Year should be a positive number"),
});

const NewCab = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(null);

  const { makeRequestWithAuth } = useAuth();
  const { setWarning, setSuccess } = useToast();
  const { resetModal } = useModal();
  const [models, setModels] = useState([]);

  const handleMakeChange = async (selectedMake) => {
    setModels(carsData[selectedMake]);
  };

  const { forceUpdate } = useForceUpdate();

  const onSubmit = async (payload) => {
    setLoading(25);

    const { ...newCabData } = payload;
    const { stateCode, registrationNumber, model, ...restOfData } = newCabData;

    const isValidRno = validateRegistrationNumber(
      stateCode,
      stateCode + registrationNumber
    );

    if (!isValidRno) {
      setLoading(null);
      return setWarning("Invalid registration number.");
    }

    const [carModel, cabType] = model.split("$");

    const cabData = {
      ...restOfData,
      registrationNumber: stateCode + registrationNumber,
      model: carModel,
      cabType,
    };

    const { error: cabErr, data: onboardCabData } = await makeRequestWithAuth({
      url: CONFIG.APIS.ONBOARD_CAB, // API CHECK
      method: "post", //giving error of driver required
      body: { ...cabData, driver: null },
    });

    setLoading(100);

    if (cabErr) {
      console.log(cabErr);
      return setWarning(
        onboardCabData?.error ||
          onboardCabData?.message ||
          "Error in onboarding cab."
      );
    } else {
      setSuccess("Cab added successfully");
      resetModal();
      forceUpdate();
      setLoading(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-3">
      <div className="mb-3">
        <h5 className="mb-4">Cab details</h5>
        <div className="input-group">
          <select
            {...register("stateCode")}
            className="form-select"
            aria-label="State Code"
            style={{ flex: 1 }}
          >
            <option value="">Select State Code</option>
            {Object.entries(stateCodes).map(([state, code]) => (
              <option key={code} value={code}>
                {code} - {state}
              </option>
            ))}
          </select>
          <input
            {...register("registrationNumber")}
            className="form-control"
            placeholder="Registration No - 21-BH-XXXX"
            style={{ flex: 3 }}
          />
        </div>
        {errors.registrationNumber && (
          <p className="text-danger">{errors.registrationNumber.message}</p>
        )}
      </div>

      <div className="input-group mb-3">
        <select
          {...register("make")}
          className="form-select"
          aria-label="Make"
          onChange={(e) => handleMakeChange(e.target.value)}
        >
          <option value="">Select Make</option>
          {Object.keys(carsData).map((make) => (
            <option key={make} value={make}>
              {make}
            </option>
          ))}
        </select>
        <select
          {...register("model")}
          className="form-select"
          aria-label="Model"
        >
          <option value="">Select Model</option>
          {models.map((modelData) => {
            const { model, cabType } = modelData;
            return (
              <option key={model} value={model + "$" + cabType}>
                {model}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        {errors.model && <p className="text-danger">{errors.model.message}</p>}
        {errors.make && <p className="text-danger">{errors.make.message}</p>}
      </div>

      <div className="mb-3">
        <select
          {...register("year")}
          className="form-select"
          aria-label="Year"
          defaultValue={2020}
        >
          <option value="">Select Year</option>
          {getYears().map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        {errors.year && <p className="text-danger">{errors.year.message}</p>}
      </div>

      {loading ? (
        <div
          className="progress"
          role="progressbar"
          aria-label="Animated striped example"
          aria-valuenow={loading}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            style={{ width: `${loading}%` }}
          ></div>
        </div>
      ) : (
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      )}
    </form>
  );
};

export default NewCab;
