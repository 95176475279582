import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOffCanvas from "../../../hooks/useOffCanvas";
import { useEffect, useState } from "react";
import Loader from "../../loader";
import Pagination from "../../pagination";
import SelectDriver from "./selectDriver";
import useAuth from "../../../hooks/useAuth";
import useToast from "../../../hooks/useToast";
import CONFIG from "../../../config";
import useForceUpdate from "../../../hooks/useForceUpdate";
import DateFormator from "../../../utils/dateFormator";

export default function InProgress() {
  const { setOffCanvas } = useOffCanvas();
  const [loading, setLoading] = useState(false);
  const { makeRequestWithAuth } = useAuth();
  const { setWarning } = useToast();

  const [bookings, setBookings] = useState({ count: null, page: 0, data: [] });

  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const tripsAsyncCall = async () => {
      setLoading(true);
      const { error, data } = await makeRequestWithAuth({
        url:
          CONFIG.APIS.GET_INPROGRESS_BOOKINGS +
          `?page=${bookings.page}` +
          "&" +
          `size=5`,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching bookings");
      }

      setBookings({
        ...bookings,
        data: data.bookings,
        count: data.totalBookings,
      });
      setLoading(false);
    };

    tripsAsyncCall();
    // eslint-disable-next-line
  }, [bookings.page, forceUpdateIndicator]);

  const changePage = (pageNo) => {
    setBookings({ ...bookings, page: pageNo, count: null });
  };

  return (
    <>
      {!loading ? (
        <>
          {bookings !== null ? (
            <div className="table-responsive">
              {bookings.data.length > 0 ? (
                <table className="table" style={{ fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Trip Id</th>
                      <th scope="col">Pickup Point</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Pickup Time</th>
                      <th scope="col">Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.data.map((d, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{d._id}</th>
                          <td>{d.pickupLocation.address}</td>
                          <td>{d.dropoffLocation.address}</td>
                          <td>
                            <DateFormator timestamp={d.startTime} />
                          </td>
                          <td>
                            <span
                              className="badge rounded-pill"
                              style={{
                                fontSize: "12px",
                                color: "white",
                                backgroundColor: CONFIG.CAB_COLORS[d.cabType],
                              }}
                            >
                              {d.cabType}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-outline-success"
                              onClick={() =>
                                setOffCanvas(
                                  `#${d._id}`,
                                  <SelectDriver booking={d} />
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faCheckCircle} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No bookings found.</p>
              )}
            </div>
          ) : (
            <p className="text-center">Error in fetching data.</p>
          )}
          {bookings.count > 5 ? (
            <Pagination
              page={bookings.page}
              changePage={changePage}
              count={bookings.count}
              offset={5}
            />
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
