export default class Notification {
  push = (state, payload) => {
    const { message, type } = payload;
    const initialState = state.notification;
    const finalState = { ...initialState, message: message, type: type };

    return { ...state, notification: finalState };
  };

  pop = (state) => {
    const initialState = state.notification;
    const finalState = { ...initialState, message: null, type: null };
    return { ...state, notification: finalState };
  };
}
