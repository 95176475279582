import "../styles/timeline.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import DateFormator from "../utils/dateFormator"
import CONFIG from "../config";
export default function Timeline({ timeline }) {

  return (
    <>
      <div className="container mt-5">
        <div className="jhandu">
          {timeline.map((line, i) => {
            return (
              <div key={i} className="timeline-block timeline-block-right">
                <div className="marker">
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />
                </div>
                <div className="timeline-content">
                  <h3>{CONFIG.STATUS_DESCRIPTION[line.status]}</h3>
                  <span>{<DateFormator timestamp={line.timestamp} />}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
