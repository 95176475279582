import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOffCanvas from "../hooks/useOffCanvas";
import { useEffect, useState } from "react";
import Loader from "../components/loader";
import Pagination from "../components/pagination";
import CONFIG from "../config";
import useAuth from "../hooks/useAuth";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmedTrackings from "../components/home/confirmedTrackings";
import useToast from "../hooks/useToast";
import DateFormator from "../utils/dateFormator";

export default function Trips() {
  const { setOffCanvas } = useOffCanvas();
  const [loading, setLoading] = useState(false);
  const { makeRequestWithAuth } = useAuth();
  const { setWarning } = useToast();

  const [bookings, setBookings] = useState({ count: null, page: 0, data: [] });

  useEffect(() => {
    const tripsAsyncCall = async () => {
      setLoading(true);
      let url =
        CONFIG.APIS.GET_COMPLETED_BOOKINGS +
        `?page=${bookings.page}` +
        "&" +
        `?perPage=${10}`;

      const { error, data } = await makeRequestWithAuth({
        url,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching bookings");
      }

      setBookings({
        ...bookings,
        data: data.bookings,
        count: data.totalBookings,
      });
      setLoading(false);
    };

    tripsAsyncCall();
    // eslint-disable-next-line
  }, [bookings.page]);

  const changePage = (pageNo) => {
    setBookings({ ...bookings, page: pageNo, count: null });
  };

  return (
    <>
      {!loading ? (
        <>
          {bookings.count !== null ? (
            <div className="table-responsive" style={{ marginTop: "100px" }}>
              {bookings.data.length > 0 ? (
                <table className="table" style={{ fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Trip Id</th>
                      <th scope="col">Pickup Point</th>
                      <th scope="col">Drop Point</th>
                      <th scope="col">Pickup Time</th>
                      <th scope="col">Drop Time</th>
                      <th scope="col">Type</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.data.map((d, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">{d._id}</th>
                          <td>{d.pickupLocation.address}</td>
                          <td>{d.dropoffLocation.address}</td>
                          <td><DateFormator timestamp={d.startTime} /></td>
                          <td><DateFormator timestamp={d.trackings[0].timestamp} /></td>
                          <td>
                            <span
                              style={{
                                maxWidth: "540px",
                                fontSize: "12px",
                                color: "white",
                                backgroundColor: CONFIG.CAB_COLORS[d.cabType],
                              }}
                              className="badge rounded-pill"
                            >
                              {d.cabType}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex">
                              <span>
                                <button
                                  className="btn btn-outline-info"
                                  onClick={() =>
                                    setOffCanvas(
                                      `#${d._id}`,
                                      <ConfirmedTrackings booking={d} />
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </button>
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No bookings found.</p>
              )}
            </div>
          ) : (
            <p className="text-center">Error in fetching data.</p>
          )}

          {bookings.data?.length > 10 ? (
            <Pagination
              page={bookings.page}
              changePage={changePage}
              count={bookings.count}
              offset={10}
            />
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
