export default class Notification {
  set = (state, payload) => {
    const { title, childComponent } = payload;
    const initialState = state.modal;
    const finalState = {
      ...initialState,
      title: title,
      childComponent: childComponent,
    };

    return { ...state, modal: finalState };
  };
}
