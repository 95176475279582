import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTaxi, faRoad, faRupeeSign } from "@fortawesome/free-solid-svg-icons";

export default function Stats(props) {
  const {
    profile: { stats },
  } = props;

  return (
    <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
      <div className="col">
        <div className="card h-100 text-center">
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="circle-icon bg-primary text-white">
              <FontAwesomeIcon color="black" icon={faTaxi} size="2x" />
            </div>
            <div className="text-start ms-3">
              <h5 className="card-title">Rides</h5>
              <p className="card-text lead">{stats.totalBookings}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card h-100 text-center">
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="circle-icon bg-danger text-white">
              <FontAwesomeIcon color="black" icon={faRupeeSign} size="2x" />
            </div>
            <div className="text-start ms-3">
              <h5 className="card-title">Revenue</h5>
              <p className="card-text lead">Rs. {Math.ceil(stats.totalFare)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card h-100 text-center">
          <div className="card-body d-flex justify-content-between align-items-center">
            <div className="circle-icon bg-success text-white">
              <FontAwesomeIcon color="black" icon={faRoad} size="2x" />
            </div>
            <div className="text-start ms-3">
              <h5 className="card-title">Distance</h5>
              <p className="card-text lead">{Math.ceil(stats.totalDistance)} km</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
