import CONFIG from "../../../config";
import useAuth from "../../../hooks/useAuth";
import useToast from "../../../hooks/useToast";
import useOffCanvas from "../../../hooks/useOffCanvas";
import Map from "../../map";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { useState } from "react";
import useModal from "../../../hooks/useModal";

export default function AcceptTrip({ booking }) {
  console.log(booking)
  const markers = [
    booking.pickupLocation.coordinates,
    booking.dropoffLocation.coordinates,
  ];
  const { makeRequestWithAuth } = useAuth();
  const [loading, setLoading] = useState(null);
  const { resetOffCanvas } = useOffCanvas();
  const { setSuccess, setWarning } = useToast();
  const { forceUpdate } = useForceUpdate();
  const { resetModal } = useModal();

  const acceptBooking = async (id) => {
    const { error } = makeRequestWithAuth({
      method: "post",
      url: CONFIG.APIS.ACCEPT_BOOKING + "/" + id + "?accepted=1",
    });

    if (error) {
      setWarning("Error in booking confirmation");
    } else {
      setSuccess("Booking accepted successfully");
      forceUpdate();
      resetOffCanvas();
    }
  };

  const rejectBooking = async (id) => {
    const { error } = makeRequestWithAuth({
      method: "post",
      url: CONFIG.APIS.ACCEPT_BOOKING + "/" + id + "?accepted=0",
    });

    if (error) {
      setWarning("Error in rejecting booking");
    } else {
      setSuccess("Booking rejected successfully");
      forceUpdate();
      resetOffCanvas();
    }
  };





  // New farea update code
  const [newFareButton, setNewFareButton] = useState(false);
  const [inputFare, setInputFare] = useState(booking.fare);
  const handleInputChange = (e) => {
    setInputFare(e.target.value);
  };
  const editHandler = async (choice) => {
    if (newFareButton === true) {
      setNewFareButton(false);

      let newFare;
      if (choice === false) setInputFare(booking.fare); // if user has opted for cancel setting price to earlier which server is giving to me
      else {
        newFare = inputFare;
        booking.fare = inputFare; // if confirmed so set to new user price
        const payload = {
          bookingId: booking._id,
          fare: newFare,
        };

        const { error } = await makeRequestWithAuth({
          url: CONFIG.APIS.UPDATEFARE,
          method: "put",
          body: JSON.stringify(payload),
        });
        setLoading(100);
        if (error) {
          return setWarning("Error in updating the fare");
        } else {
          setInputFare(newFare);
          setSuccess("Fare updated successfully");
          resetModal();
          forceUpdate();
          setLoading(null);
        }
      }
    } else setNewFareButton(true);
  };

  return (
    <>
      <Map markers={markers} />
      <table className="table" style={{ fontSize: "14px", marginTop: "50px" }}>
        <tbody>
          <tr>
            <th scope="row">Pickup Point</th>
            <td>{booking.pickupLocation.address}</td>
          </tr>
          <tr>
            <th scope="row">Drop Point</th>
            <td>{booking.dropoffLocation.address}</td>
          </tr>
          <tr>
            <th scope="row">Fare</th>
            <td>
              {newFareButton === false ? (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  Rs. {Math.ceil(inputFare)}
                  <button className="btn btn-warning btn-sm" onClick={() => editHandler()}>
                    {loading ? "Loading..." : "Edit"}
                  </button>
                </div>
              ) : (
                <div className="row">
                  <div className="col">
                    <input
                      type="number"
                      value={inputFare}
                      onChange={handleInputChange}
                      placeholder="Enter Fare..."
                      className="form-control"
                    />
                  </div>
                  <div className="col-auto">
                    {/* 0--->cancel
                    1--->confirm */}
                    <button className="btn btn-danger btn-sm" onClick={() => editHandler(false)}>Cancel</button>
                  </div>
                  <div className="col-auto">
                    <button className="btn btn-success btn-sm" onClick={() => editHandler(true)}>Confirm</button>
                  </div>

                </div>
              )}
            </td>
          </tr>
          <tr>
            <th scope="row">distance</th>
            <td>{Math.ceil(booking.distance)} Km</td>
          </tr>
        </tbody>
      </table>
      <button
        className="btn btn-outline-success mt-5"
        onClick={() => acceptBooking(booking._id)}
      >
        Accept
      </button>


      <button
        className="btn btn-outline-danger mt-5 ms-3"
        onClick={() => rejectBooking(booking._id)}
        style={{ backgroundColor: 'transparent', borderColor: '#dc3545', color: '#dc3545' }}
        onMouseOver={(e) => {
          e.target.style.backgroundColor = '#ff0000';
          e.target.style.color = '#fff';
        }}
        onMouseOut={(e) => {
          e.target.style.backgroundColor = 'transparent';
          e.target.style.color = '#dc3545';
        }}
      >
        Reject
      </button>




      <button
        className="btn btn-grey mt-5 ms-3"
        disabled
      >
        Share
      </button>
    </>
  );
}
