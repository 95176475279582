export const getYears = () => {
  const startYear = 1970; // You can adjust the start year as needed
  const endYear = new Date().getFullYear(); // Current year

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
};
