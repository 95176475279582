import { createContext, useEffect, useState, useReducer } from "react";
import Reducer from "./reducer";
export const Context = createContext();

export const Store = ({ children }) => {
  let initialState = {
    user: {
      name: null,
      email: null,
      accessToken: null,
      refreshToken: null,
    },
    notification: {
      message: null,
      type: null,
    },
    offcanvas: {
      title: null,
      childComponent: null,
    },
    modal: {
      title: null,
      childComponent: null,
    },
  };

  const [store] = useState(() => {
    const savedUser = localStorage.getItem("bgl-user");

    if (savedUser) {
      initialState = { ...initialState, user: JSON.parse(savedUser) };
    }

    return initialState;
  });

  const [state, dispatch] = useReducer(Reducer, store);

  const forceUpdateReducerFn = (x) => {
    console.log(" - - - -  F o r c e  u p d a t e  - - - - ");
    return !x;
  };

  const [forceUpdateIndicator, forceUpdate] = useReducer(
    forceUpdateReducerFn,
    true
  );

  useEffect(() => {
    localStorage.setItem("bgl-user", JSON.stringify(state.user));
  });

  return (
    <Context.Provider
      value={{ state, dispatch, forceUpdateIndicator, forceUpdate }}
    >
      {children}
    </Context.Provider>
  );
};
