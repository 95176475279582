import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CONFIG from "../../../config";
import {
  faCheckCircle,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import useToast from "../../../hooks/useToast";

export default function CopyLinkToClipBoard({ bookingId }) {
  const [copied, setCopied] = useState(false);

  const { setWarning } = useToast();

  const linkToCopy = CONFIG.BOOKING_CUSTOMER_LINK + `/${bookingId}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => {
        console.error(err);
        setWarning("Failed to copy link");
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between p-1">
        <span>
          <input className="form-control" readOnly value={linkToCopy}></input>
        </span>
        <span>
          <button
            className={`btn btn-outline-${copied ? "success" : "warning"}`}
          >
            <FontAwesomeIcon
              onClick={() => copyToClipboard()}
              icon={copied ? faCheckCircle : faClipboard}
            />
          </button>
        </span>
      </div>
    </>
  );
}
