import {
  faDriversLicense,
  faPhoneAlt,
  faPlus,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "../hooks/useModal";
import useAuth from "../hooks/useAuth";
import { useEffect, useState } from "react";
import CONFIG from "../config";
import useToast from "../hooks/useToast";
import Loader from "../components/loader";
import NewCab from "../components/newCabForm";
import Pagination from "../components/pagination";
import useForceUpdate from "../hooks/useForceUpdate";
import Unassign from "../components/Unassign";
import Assign from "../components/assignDriver";

export default function Cabs() {
  const { setModal } = useModal();
  const { makeRequestWithAuth } = useAuth();
  const { setWarning } = useToast();
  const [loading, setLoading] = useState(false);
  //const [assign, setAssign] = useState(false);

  const [cabs, setCabs] = useState({ count: null, page: 0, data: [] });

  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const tripsAsyncCall = async () => {
      setLoading(true);
      const { error, data } = await makeRequestWithAuth({
        url:
          CONFIG.APIS.GET_PAGINATED_CABS +
          `?page=${cabs.page}` +
          "&" +
          `size=6`,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching cabs");
      }

      setCabs({
        ...cabs,
        data: data.cabs,
        count: data.totalCabs,
      });

      setLoading(false);
    };

    tripsAsyncCall();
    // eslint-disable-next-line
  }, [cabs.page, forceUpdateIndicator]);

  const changePage = (pageNo) => {
    setCabs({ ...cabs, page: pageNo, count: null });
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-5">
        <button
          className="btn btn-outline-primary"
          onClick={() => setModal("New Cab", <NewCab />)}
        >
          Onboard Cab <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      <div className="row mt-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            {cabs.data.map((cab, i) => {
              return (
                <div key={i} className="col-md-4">
                  <div className="card mb-3 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <span>
                          <strong>{cab.registrationNumber}</strong>
                          <p>
                            {cab.make} {cab.model} {cab.year}
                          </p>
                        </span>
                        <span
                          style={{
                            color: CONFIG.CAB_COLORS[cab.cabType],
                          }}
                        >
                          {cab.cabType}
                        </span>
                      </div>

                      {/* ....................... */}
                      {cab.driver ? (
                        <div>
                          <small>
                            <strong>
                              <FontAwesomeIcon
                                icon={faUserAlt}
                                className="me-3"
                              />{" "}
                            </strong>{" "}
                            {cab.driver.user.name}
                          </small>{" "}
                          <br />
                          <small>
                            <strong>
                              <FontAwesomeIcon
                                icon={faPhoneAlt}
                                className="me-3"
                              />
                            </strong>{" "}
                            {cab.driver.user.phoneNumber}
                          </small>{" "}
                          <br />
                          <small>
                            <FontAwesomeIcon
                              icon={faDriversLicense}
                              className="me-3"
                            />{" "}
                            {cab.driver.licenseNumber}
                          </small>
                          <br />
                          <button
                            className="btn btn-outline-danger mt-5 ms-3"
                            onClick={() =>
                              setModal(
                                "Unassign Driver ?",
                                <Unassign cabId={cab._id} />
                              )
                            }
                          >
                            Unassign Driver
                          </button>
                        </div>
                      ) : (
                        <>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() =>
                              setModal(
                                "Assign Driver",
                                <Assign cabId={cab._id} />
                              )
                            }
                          >
                            Assign Driver
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {cabs.count > 5 ? (
        <Pagination
          page={cabs.page}
          changePage={changePage}
          count={cabs.count}
          offset={6}
        />
      ) : null}
    </>
  );
}
