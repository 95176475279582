import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useToast from "../hooks/useToast";
import CONFIG from "../config";
import useForceUpdate from "../hooks/useForceUpdate";
import useModal from "../hooks/useModal";

const Assign = ({ cabId }) => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const [loading, setLoading] = useState(false);

  const { makeRequestWithAuth } = useAuth();
  const { setWarning, setSuccess } = useToast();
  const { forceUpdate } = useForceUpdate();
  const { resetModal } = useModal();

  useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      const { error, data } = await makeRequestWithAuth({
        url: CONFIG.APIS.GET_DRIVERS,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching drivers");
      } else {
        const unassignedDrivers = data.drivers.filter(driver => !driver.assigned);
        setDrivers(unassignedDrivers);
      }
      setLoading(false);
    };

    fetchDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDriverId === "") {
      setWarning("Please select a driver");
      return;
    }

    const { error, data } = await makeRequestWithAuth({
      url: CONFIG.APIS.ASSIGN_NEW_DRIVER,
      method: "put",
      body: JSON.stringify({ cab: cabId, driver: selectedDriverId }),
    });

    if (error) {
      return setWarning(
        data?.error || data?.message || "Error in selecting driver."
      );
    }
    setSuccess("Driver selected successfully");
    resetModal();
    forceUpdate();
  };

  return (
    <form onSubmit={handleSubmit} className="p-3">
      <h5 className="my-4">Select Driver</h5>
      {loading ? (
        <p>Loading drivers...</p>
      ) : (
        <select
          className="form-select"
          value={selectedDriverId}
          onChange={(e) => setSelectedDriverId(e.target.value)}
        >
          <option value={""}>Select a driver</option>
          {drivers.map((driver) => (
            <option key={driver._id} value={driver._id}>
              {driver.user.name} {driver.user.phoneNumber}
            </option>
          ))}
        </select>
      )}
      <button type="submit" className="btn btn-primary mt-3">
        Submit
      </button>
    </form>
  );
};

export default Assign;
