import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import useToast from "../hooks/useToast";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { countryCodes } from "../utils/countryCodes";

const schema = yup.object().shape({
  countryCode: yup.string().required("Country code is required"),
  phoneNumber: yup
    .string()
    .matches(/^[6-9]\d{9}$/)
    .required("*Phone Number is required"),
});

export default function LoginForm() {
  const { loginUser, sendOtp } = useAuth();
  const { setSuccess } = useToast();
  const [otpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState(null);
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let initialAlert = { icon: null, message: null, background: null };
  let [alert, setAlert] = useState(initialAlert);

  let formConfig = {
    resolver: yupResolver(schema),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formConfig);

  const alerter = {
    warning: (error) => {
      setAlert({
        icon: faExclamationTriangle,
        message: error,
        background: "danger",
      });
    },
  };

  const sendOtpForm = async (data) => {
    setAlert(initialAlert);
    setLoading(true);
    const { error, data: response } = await sendOtp(
      `${data.countryCode} ${data.phoneNumber}`
    );
    setLoading(false);
    if (error) alerter.warning(response.error);
    else {
      setSuccess(response.message);
      setPhone(`${data.countryCode} ${data.phoneNumber}`);
      setOtpScreen(true);
    }
  };

  const loginUserForm = async (e) => {
    e.preventDefault();
    setAlert(initialAlert);
    setLoading(true);
    const { error, data: response } = await loginUser(phone, otp);
    setLoading(false);
    if (error) alerter.warning(response.error);
    else {
      setSuccess(response.message);
      navigate("/");
    }
  };

  return (
    <>
      {otpScreen ? (
        <>
          <p className="lead">Enter 4 digit OTP sent to your mobile number</p>
          <form onSubmit={loginUserForm}>
            <div className="form-outline mb-4">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                inputStyle="form-control form-control-lg"
                skipDefaultStyles={true}
                renderSeparator={<span className="p-2"></span>}
                renderInput={(props) => <input {...props} />}
                isInputNum={true}
                pattern="[0-9]*"
              />
            </div>

            <div className="d-flex justify-content-center">
              {otp.length === 4 && (
                <button
                  type="submit"
                  className={
                    "btn btn-outline-success col-12" +
                    (loading ? " disabled" : "")
                  }
                >
                  {loading ? <FontAwesomeIcon icon={faSpinner} /> : "Login"}
                </button>
              )}
            </div>
          </form>
        </>
      ) : (
        <>
          <p className="lead">Enter your mobile number</p>
          <form onSubmit={handleSubmit(sendOtpForm)}>
            <div className="form-outline mb-4">
              <div className="input-group">
                <select
                  {...register("countryCode")}
                  className="form-select"
                  style={{ flex: 1 }}
                >
                  {Object.keys(countryCodes).map((key, index) => {
                    return (
                      <option key={index} value={"+" + countryCodes[key]}>
                        ({key}) +{countryCodes[key]}
                      </option>
                    );
                  })}
                </select>
                <input
                  placeholder="Phone no."
                  type="tel"
                  {...register("phoneNumber")}
                  name="phoneNumber"
                  id="phoneNumber"
                  pattern="[0-9]*"
                  style={{ flex: 3 }}
                  className={
                    "form-control form-control-lg" +
                    (errors.phoneNumber ? " is-invalid" : "")
                  }
                />
              </div>
              {errors.phoneNumber && (
                <div className="invalid-feedback">
                  {errors.phoneNumber?.message}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className={
                  "btn btn-outline-success col-12" +
                  (loading ? " disabled" : "")
                }
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} /> : "Send Otp"}
              </button>
            </div>
          </form>
        </>
      )}

      <div className="mt-5">
        {alert.message && (
          <div
            className={
              "alert alert-" + alert.background + " d-flex align-items-center"
            }
            role="alert"
          >
            <FontAwesomeIcon icon={alert.icon} className="mx-2 mb-1" />
            <div>{alert.message}</div>
          </div>
        )}
      </div>
    </>
  );
}
