import Timeline from "../timeline";

export default function ConfirmedTrackings({ booking }) {
  return (
    <>
      <table className="table" style={{ fontSize: "14px" }}>
        <tbody>
          <tr>
            <th scope="row">fare</th>
            <td>Rs. {Math.ceil(booking.fare)}</td>
          </tr>
          <tr>
            <th scope="row">distance</th>
            <td>{Math.ceil(booking.distance)} Km</td>
          </tr>
        </tbody>
      </table>
      <Timeline timeline={booking.trackings} />
    </>
  );
}
