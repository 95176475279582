import routes from "../routes";
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faInr } from "@fortawesome/free-solid-svg-icons";
import useForceUpdate from "../hooks/useForceUpdate";
import CONFIG from "../config";
import useModal from "../hooks/useModal";
import useAuth from "../hooks/useAuth";
import useToast from "../hooks/useToast";
import Farekm from "../components/farekm";


export default function Navbar() {

  const { pathname } = useLocation();
  const { auth, logoutUser } = useAuth();
  const { makeRequestWithAuth } = useAuth();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const [textVis, setTextVis] = useState(true);
  const { setWarning } = useToast();
  useEffect(() => {
    setTextVis(width > 768);
  }, [width, height]);

  const [profile, setProfile] = useState(null);
  const { setModal } = useModal();

  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      const { error: prfErr, data: profileData } = await makeRequestWithAuth({
        url: CONFIG.APIS.GET_PROFILE,
        method: "get",
      });

      if (prfErr) {
        setWarning("Error in fetching profile");
      } else {
        setProfile(profileData);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [forceUpdateIndicator]);

  const makeUserLogout = () => {
    logoutUser();
    navigate("/login");
  };

  return (
    <div className="">
      {textVis && (
        <div className="pointy p-2 mt-2">
          <h4 className="logo pointy"> </h4>
        </div>
      )}
      <div>
        <ul className="list-group list-group-flush mt-5">
          {routes.map(({ path, name, prvt, nav, navIcon }, i) => {
            if (
              (prvt && !auth) ||
              !nav ||
              (auth && (path.includes("register") || path.includes("login")))
            )
              return null;
            return (
              <Link
                key={i}
                to={path}
                className={
                  "list-group-item" + (path === pathname ? " active" : "")
                }
              >
                <div>
                  <FontAwesomeIcon icon={navIcon} />{" "}
                  {textVis && <span>{name}</span>}
                </div>
              </Link>
            );
          })}

          <div className="list-group-item pointy" onClick={() => setModal("Fare Reset", <Farekm profile={profile} />)}>
            <FontAwesomeIcon icon={faInr} />{" "}
            {textVis && <span>Adjust Pricing</span>}
          </div>


          {/* { auth && <DropDown setNav={setNavState}/>} */}
          <li
            className="mt-5 list-group-item pointy"
            onClick={() => makeUserLogout()}
          >
            <div>
              <FontAwesomeIcon icon={faSignOutAlt} />{" "}
              {textVis && <span>Logout</span>}
            </div>
          </li>
        </ul>

      </div>
    </div>
  );
}
