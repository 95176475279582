import { useContext } from "react";
import { Context } from "../context/store";
import TYPES from "../context/types";

export default function useModal() {
  const { dispatch } = useContext(Context);

  const setModal = (title, childComponent) => {
    dispatch({
      type: TYPES.MODAL.SET_MODAL,
      payload: {
        title,
        childComponent,
      },
    });
  };

  const resetModal = () => {
    dispatch({
      type: TYPES.MODAL.SET_MODAL,
      payload: {
        title: null,
        childComponent: null,
      },
    });
  };

  return {
    setModal,
    resetModal,
  };
}
