import { useEffect, useState } from "react";
import CONFIG from "../../../config";
import useOffCanvas from "../../../hooks/useOffCanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../pagination";
import OnGoingTracking from "./ongoingTracking";
import useAuth from "../../../hooks/useAuth";
import useToast from "../../../hooks/useToast";
import useForceUpdate from "../../../hooks/useForceUpdate";
import DateFormator from "../../../utils/dateFormator";

export default function OnGoing() {
  const { setOffCanvas } = useOffCanvas();
  const { makeRequestWithAuth } = useAuth();
  const { setWarning } = useToast();

  const [bookings, setBookings] = useState({ count: null, page: 0, data: [] });

  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const tripsAsyncCall = async () => {
      const { error, data } = await makeRequestWithAuth({
        url:
          CONFIG.APIS.GET_CONFIRMED_BOOKINGS +
          `?page=${bookings.page}` +
          "&" +
          `size=3`,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching bookings");
      }

      setBookings({
        ...bookings,
        data: data.bookings,
        count: data.totalBookings,
      });
    };

    tripsAsyncCall();
    // eslint-disable-next-line
  }, [bookings.page, forceUpdateIndicator]);

  const changePage = (pageNo) => {
    setBookings({ ...bookings, page: pageNo, count: null });
  };

  return (
    <>
      {" "}
      {bookings.data.length > 0 && <p className="lead fw-semibold mt-5">Ongoing Rides</p>}
      <div className="row d-flex align-items-stretch pointy">
        {bookings.data.length > 0 &&
          bookings.data.map((booking, i) => {
            return (
              <div key={i} className="col-md-4">
                <div
                  className="card mb-3"
                  style={{ maxWidth: "540px", fontSize: "12px" }}
                  onClick={() =>
                    setOffCanvas(
                      `#${booking._id}`,
                      <OnGoingTracking booking={booking} />
                    )
                  }
                >
                  <div>
                    <div className="card-body">
                      <p ><span className="fw-bold">Ride : #</span>{booking.id}</p>
                      <p className="card-text">
                        <strong>
                          {booking.pickupLocation.pincode.Districtname}
                        </strong>{" "}
                        <FontAwesomeIcon icon={faArrowRight} />{" "}
                        <strong>
                          {booking.dropoffLocation.pincode.Districtname}
                        </strong>
                      </p>
                      <p>
                        <span className="fw-bold">Driver : </span>
                        {booking.cab?.driver?.user?.name || "No Driver"}
                        <br />
                        <br />
                        <span className="fw-bold">Cab : </span>
                        <small className="text-muted">
                          {booking.cab.make} {booking.cab.model}{" "}
                          {booking.cab.registrationNumber}
                        </small>
                      </p>

                      <div ><span className="fw-bold">Start Time : </span><DateFormator timestamp={booking.startTime} /> </div>
                      <br />
                      <div>
                        <span
                          style={{
                            maxWidth: "540px",
                            fontSize: "12px",
                            color: "white",
                            backgroundColor:
                              CONFIG.STATUS_COLORS[booking.status],
                          }}
                          className="badge rounded-pill me-2"
                        >
                          {CONFIG.STATUS_DESCRIPTION[booking.status]}
                        </span>

                        <span
                          style={{
                            maxWidth: "540px",
                            fontSize: "12px",
                            color: "white",
                            backgroundColor: CONFIG.CAB_COLORS[booking.cabType],
                          }}
                          className="badge rounded-pill me-2"
                        >
                          {booking.cabType}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {bookings.count > 3 ? (
        <Pagination
          page={bookings.page}
          changePage={changePage}
          count={bookings.count}
          offset={3}
        />
      ) : null}
    </>
  );
}

