import { Context } from "../context/store";
import { useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "../styles/toast.css";

export default function SnackBar() {
  const { state } = useContext(Context);
  const { type, message } = state.notification;

  useEffect(() => {
    const toastOptions = {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };

    if (type === "error") toast.warning(message, toastOptions);
    else toast.success(message, toastOptions);
  }, [message, type]);

  return (
    <ToastContainer
      toastClassName="toastfont"
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
