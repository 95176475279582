import Navbar from "./navbar";
import Snackbar from "./snackbar";
import { useLocation } from "react-router-dom";
import OffCanvas from "./offcanvas";
import Modal from "./modal";

export default function Container({ children }) {
  const fullScreenPages = ["login"];

  const { pathname } = useLocation();

  const fullScreen = fullScreenPages.includes(pathname.replace("/", ""));

  return (
    <>
      <nav className="navbar navbar-light bg-white shadow-sm">
        <a className="navbar-brand py-2 px-3" href="/">
          <img
            src="https://i.imgur.com/b0gzQq8.png"
            className="logo-container"
            alt="bhoogol"
          />
        </a>
      </nav>
      <div className="container-fluid">
        {!fullScreen ? (
          <div className="row d-flex">
            <div className="col-2 min-vh-100 bg-none shadow-lg bg-white rounded">
              <Navbar />
            </div>
            <div
              className={"col-9 mt-3"}
              style={{ flex: 1, overflowY: "auto" }}
            >
              {children}
              <Modal />
              <Snackbar />
            </div>
          </div>
        ) : (
          <div>
            {children}
            <Modal />
            <Snackbar />
          </div>
        )}

        <OffCanvas />
      </div>
    </>
  );
}
