import {
  faBookBookmark,
  faCar,
  faHome,
  faList,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import Home from "./pages/home";
import Login from "./pages/login";
import Cabs from "./pages/cabs";
import Trips from "./pages/trips";
import CreateBooking from "./pages/createBooking";
import Drivers from "./pages/drivers";

const routes = [
  {
    path: "/",
    name: "Home",
    Page: Home,
    nav: true,
    prvt: true,
    title: "Home",
    navIcon: faHome,
  },
  {
    path: "/cabs",
    name: "Cabs",
    Page: Cabs,
    nav: true,
    prvt: true,
    title: "Cabs",
    navIcon: faCar,
  },
  {
    path: "/drivers",
    name: "Drivers",
    Page: Drivers,
    nav: true,
    prvt: true,
    title: "Register Driver",
    navIcon: faIdCard,
  },
  {
    path: "/trips",
    name: "Trips",
    Page: Trips,
    nav: true,
    prvt: true,
    title: "Trips",
    navIcon: faList,
  },
  {
    path: "/login",
    name: "Login",
    Page: Login,
    nav: false,
    prvt: false,
    title: "Login",
    navIcon: null,
  },
  {
    path: "/create-booking",
    name: "Create Booking",
    Page: CreateBooking,
    nav: true,
    prvt: true,
    title: "Create Booking",
    navIcon: faBookBookmark,
  },
];

export default routes;
