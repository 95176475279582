export default function DateFormator(props) {
  const MonthMap = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };


  const { timestamp } = props
  const [date, time] = timestamp.split("T");
  const [yyyy, mm, dd] = date.split("-");

  // Handling .000Z in the time part
  const [hh, min] = time.split(":");

  return `${hh}:${min} , ${dd} ${MonthMap[mm]} ${yyyy} `;
}
