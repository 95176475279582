import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import useToast from "../hooks/useToast";
import useForceUpdate from "../hooks/useForceUpdate";
import useModal from "../hooks/useModal";
import CONFIG from "../config";

const Unassign = ({ cabId }) => {
  const { makeRequestWithAuth } = useAuth();
  const [loading, setLoading] = useState(null);

  const { setSuccess, setWarning } = useToast();
  const { forceUpdate } = useForceUpdate();
  const { resetModal } = useModal();

  const submitHandler = async () => {
    setLoading(true);
    const { error, data } = await makeRequestWithAuth({
      url: CONFIG.APIS.UNASSIGN_DRIVER_FROM_CAB + "/" + cabId, //API DEVANSH SIR
      method: "put",
    });

    setLoading(false);

    if (error) {
      return setWarning(
        data?.error || data?.message || "Error in unassigning driver."
      );
    } else {
      setSuccess("Unassigned Successfully");
      resetModal();
      forceUpdate();
      setLoading(null);
    }
  };

  return (
    <>
      <p>Are you sure, do you want to unassign this cab with no drivers?</p>
      {loading ? (
        "loading.."
      ) : (
        <button className="btn btn-danger" onClick={() => submitHandler()}>
          Unassign
        </button>
      )}
    </>
  );
};

export default Unassign;
