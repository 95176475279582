import React, { useEffect } from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import useLoadScript from "../hooks/useLoadScript";
import { mapStyles } from "../styles/mapStyles";

const containerStyle = {
  width: "350px",
  height: "250px",
};

function transformMarkers(markers) {
  let tMarkers = [];
  markers.forEach(([lng, lat], i) => (tMarkers[i] = { lat, lng }));
  return tMarkers;
}

function MapComp({ markers, showEta = false }) {
  const tMarkers = transformMarkers(markers);

  const center = tMarkers[0];

  const [map, setMap] = React.useState(null);
  const [isInitialized, setIsInitialized] = React.useState(false);

  const onLoad = React.useCallback(function callback(map) {
    const google = (window.google = window.google ? window.google : {});
    if (!isInitialized && google.maps) {
      setIsInitialized(true);
    }

    setMap(map);
    // eslint-disable-next-line
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const [response, setResponse] = React.useState(null);

  const [eta, setEta] = React.useState(null);

  const directionsCallback = (result, status) => {
    if (status === "OK") {
      if (!response) {
        setResponse(result);
        if (
          result.routes &&
          result.routes.length > 0 &&
          result.routes[0].legs &&
          result.routes[0].legs.length > 0
        ) {
          const duration = result.routes[0].legs[0].duration.text;
          setEta(duration);
        }
      }
    } else {
      console.error("Directions request failed due to ", status);
    }
  };

  const directionsRequest = {
    origin: tMarkers[0], // Replace with your origin coordinates
    destination: tMarkers[1], // Replace with your destination coordinates
    travelMode: "DRIVING", // Change this if you want different travel mode (DRIVING, WALKING, BICYCLING, TRANSIT)
  };
  const { isLoaded } = useLoadScript();

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      // eslint-disable-next-line
      tMarkers.map((marker) => {
        bounds.extend(marker);
      });
      map.fitBounds(bounds);
    }
  }, [map, tMarkers]);

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        options={{ styles: mapStyles, disableDefaultUI: true }}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <>
          <DirectionsService
            options={{
              destination: directionsRequest.destination,
              origin: directionsRequest.origin,
              travelMode: directionsRequest.travelMode,
            }}
            callback={directionsCallback}
          />
          {response && <DirectionsRenderer directions={response} />}
        </>
      </GoogleMap>
      {showEta && eta && (
        <p style={{ marginTop: "20px" }}>
          Estimated Time of Arrival: <strong>{eta}</strong>
        </p>
      )}
    </>
  ) : (
    <></>
  );
}

export default React.memo(MapComp);
