import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import CONFIG from "../config";
import useToast from "../hooks/useToast";
import useModal from "../hooks/useModal";
import useForceUpdate from "../hooks/useForceUpdate";
import { countryCodes } from "../utils/countryCodes";

const schema = yup.object().shape({
  firstName: yup.string().required("*First name is required").min(1).max(15),
  middleName: yup.string().default(null).max(15).optional(),
  lastName: yup.string().required("*Last name is required").min(1).max(15),
  countryCode: yup.string().required("*Country code is required"),
  phoneNumber: yup
    .string()
    .matches(/^[6-9]\d{9}$/)
    .required("*Phone Number is required"),
});

const NewDriver = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(null);

  const { makeRequestWithAuth } = useAuth();
  const { setWarning, setSuccess } = useToast();
  const { resetModal } = useModal();

  const { forceUpdate } = useForceUpdate();

  const onSubmit = async (payload) => {
    setLoading(25);

    const {
      firstName,
      middleName,
      lastName,
      licenseNumber,
      phoneNumber,
      countryCode,
    } = payload;

    const { error, data } = await makeRequestWithAuth({
      url: CONFIG.APIS.ONBOARD_DRIVER,
      method: "post",
      body: {
        name: middleName
          ? `${firstName} ${middleName} ${lastName}`
          : `${firstName} ${lastName}`,
        licenseNumber,
        phoneNumber: `${countryCode} ${phoneNumber}`,
      },
    });

    if (error) {
      return setWarning(
        data?.error || data?.message || "Error in onboarding driver."
      );
    } else {
      setSuccess("Driver added successfully");
      resetModal();
      forceUpdate();
      setLoading(null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-3">
      <div className="mb-3">
        <h5 className="my-4">Driver details</h5>

        <div className="input-group mb-3">
          <input
            {...register("firstName")}
            className="form-control"
            placeholder="First Name"
          />

          <input
            {...register("middleName")}
            className="form-control"
            placeholder="Middle Name"
          />

          <input
            {...register("lastName")}
            className="form-control"
            placeholder="Last Name"
          />
        </div>

        <div>
          {errors.firstName && (
            <p className="text-danger">{errors.firstName.message}</p>
          )}
          {errors.middleName && (
            <p className="text-danger">{errors.middleName.message}</p>
          )}
          {errors.lastName && (
            <p className="text-danger">{errors.lastName.message}</p>
          )}
        </div>

        <div className="form-outline mb-4">
          <div className="input-group">
            <select
              {...register("countryCode")}
              className="form-select"
              style={{ flex: 1 }}
            >
              {Object.keys(countryCodes).map((key, index) => {
                return (
                  <option key={index} value={"+" + countryCodes[key]}>
                    ({key}) +{countryCodes[key]}
                  </option>
                );
              })}
            </select>
            <input
              placeholder="Phone no."
              type="tel"
              {...register("phoneNumber")}
              name="phoneNumber"
              id="phoneNumber"
              pattern="[0-9]*"
              style={{ flex: 3 }}
              className={
                "form-control" + (errors.phoneNumber ? " is-invalid" : "")
              }
            />
          </div>
          {errors.phoneNumber && (
            <div className="invalid-feedback">
              {errors.phoneNumber?.message}
            </div>
          )}
        </div>

        <div className="mb-3">
          <input
            {...register("licenseNumber")}
            className="form-control"
            placeholder="License Number"
          />
        </div>

        {loading ? (
          <div
            className="progress"
            role="progressbar"
            aria-label="Animated striped example"
            aria-valuenow={loading}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: `${loading}%` }}
            ></div>
          </div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        )}
      </div>
    </form>
  );
};

export default NewDriver;
