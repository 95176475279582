export default class OffCanvas {
  set = (state, payload) => {
    const { title, childComponent } = payload;
    const initialState = state.offcanvas;
    const finalState = {
      ...initialState,
      title: title,
      childComponent: childComponent,
    };

    return { ...state, offcanvas: finalState };
  };
}
