const TYPES = {
  USER: {
    ADD_USER: "ADD_USER",
    REMOVE_USER: "REMOVER_USER",
    REFRESH_ACCESS_TOKEN: "REFRESH_TOKEN_TOKEN",
  },
  TOAST: {
    SET_NOTIFICATION: "SET_NOTIFICATION",
    RESET: "RESET",
  },
  OFFCANVAS: {
    SET_OFFCANVAS: "SET_OFFCANVAS",
  },
  MODAL: {
    SET_MODAL: "SET_MODAL",
  },
};

export default TYPES;
