import { useState, useEffect } from "react";
import Stats from "../components/home/stats";
import Tab from "../components/tabs";
import InProgress from "../components/home/requests-sub/inprogress";
import Requests from "../components/home/requests-sub/requests";
import CONFIG from "../config";
import useAuth from "../hooks/useAuth";
import OnGoing from "../components/home/requests-sub/ongoing";
import useToast from "../hooks/useToast";
import useForceUpdate from "../hooks/useForceUpdate";

export default function Home() {
  const { makeRequestWithAuth } = useAuth();
  const { setWarning } = useToast();
  const [profile, setProfile] = useState(null);


  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const fetchData = async () => {
      const { error: prfErr, data: profileData } = await makeRequestWithAuth({
        url: CONFIG.APIS.GET_PROFILE,
        method: "get",
      });

      if (prfErr) {
        setWarning("Error in fetching profile");
      } else {
        setProfile(profileData);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [forceUpdateIndicator]);

  const [tab, setTab] = useState("Requests");

  return (
    <>

      {profile && <p className="lead p-2 fw-semibold fs-3"> Hi, {profile.taxiService.owner}</p>}
      {profile && <Stats profile={profile} />}

      <Tab tabs={["Requests", "In Progess"]} currentTab={tab} setTab={setTab} />
      <div className="container" style={{ marginTop: "50px" }}>
        {tab === "Requests" ? <Requests /> : <InProgress />}
      </div>
      <OnGoing />
    </>
  );
}
