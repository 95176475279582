import { useState } from "react";
import CONFIG from "../config";
import useToast from "../hooks/useToast";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "../hooks/useAuth";
import LocationSearch from "../components/locationSearch";
import { countryCodes } from "../utils/countryCodes";

const schema = yup.object().shape({
  startTime: yup.string().required("Please give trip start time"),
  countryCode: yup.string().required("Country code is required"),
  name: yup.string().required("Name is required"),
  phoneNumber: yup.string().min(10).required("Phone Number is required"),
  email: yup
    .mixed()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable(true),
  cabType: yup.string().required("Cab Type is required"),
  fare: yup
    .mixed()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .nullable(true),
});

export default function CreateBooking() {
  const [pickupCoordinates, setPickupCoordinates] = useState({
    lat: null,
    lng: null,
    address: null,
  });
  const [dropCoordinates, setDropCoordinates] = useState({
    lat: null,
    lng: null,
    address: null,
  });

  const [pickupError, setPickupError] = useState(false);
  const [dropError, setDropError] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(null);

  const { makeRequestWithAuth } = useAuth();
  const { setWarning, setSuccess } = useToast();

  const onSubmit = async (payload) => {
    setPickupError(false);
    setDropError(false);

    setLoading(50);

    if (!pickupCoordinates.lat && !pickupCoordinates.lng) {
      setPickupError(true);
      return;
    }

    if (!dropCoordinates.lat && !dropCoordinates.lng) {
      setDropError(true);
      return;
    }

    const { name, phoneNumber, countryCode, startTime, email, cabType, fare } =
      payload;
    const fareValue = fare === null ? null : fare;
    const emailValue = email === null ? null : email;

    const { error, data } = await makeRequestWithAuth({
      url: CONFIG.APIS.REQUEST_BOOKING,
      method: "post",
      body: {
        customer: {
          phoneNumber: `${countryCode} ${phoneNumber}`,
          name,
          email: emailValue,
        },
        pickupLocation: {
          type: "Point",
          coordinates: [pickupCoordinates.lng, pickupCoordinates.lat],
          address: pickupCoordinates.address,
        },
        dropoffLocation: {
          type: "Point",
          coordinates: [dropCoordinates.lng, dropCoordinates.lat],
          address: dropCoordinates.address,
        },
        startTime,
        cabType,
        fare: fareValue,
      },
    });

    if (error) {
      setWarning(
        data?.error || data?.message || "Error in requesting new booking"
      );
      setLoading(null);
      return;
    }

    setSuccess("Booking request made successfully");

    reset();
    setLoading(null);
  };

  return (
    <>
      <span className="p-2 my-3"></span>
      <div className="row">
        <div className="col-12 col-md-6">
          <LocationSearch
            title="Pickup Location"
            setCoordinates={setPickupCoordinates}
          />
          {pickupError && (
            <p className="text-danger">Please provide pickup location.</p>
          )}
        </div>
        <div className="col-12 col-md-6">
          <LocationSearch
            title="Drop Location"
            setCoordinates={setDropCoordinates}
          />
          {dropError && (
            <p className="text-danger">Please provide drop location.</p>
          )}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-1">
        <div className="mb-3">
          <input
            {...register("name")}
            className="form-control"
            placeholder="Name"
          />
          {errors.name && <p className="text-danger">{errors.name.message}</p>}
        </div>

        <div className="mb-3">
          <Controller
            name="startTime"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                type="datetime-local"
                id="startTime"
                className="form-control"
                name="startTime"
                {...field}
              />
            )}
          />
          {errors.startTime && (
            <p className="text-danger">{errors.startTime.message}</p>
          )}
        </div>

        <div className="form-outline mb-4">
          <div className="input-group">
            <select
              {...register("countryCode")}
              className="form-select"
              style={{ flex: 1 }}
            >
              {Object.keys(countryCodes).map((key, index) => {
                return (
                  <option key={index} value={"+" + countryCodes[key]}>
                    ({key}) +{countryCodes[key]}
                  </option>
                );
              })}
            </select>
            <input
              placeholder="Phone no."
              type="tel"
              {...register("phoneNumber")}
              name="phoneNumber"
              id="phoneNumber"
              pattern="[0-9]*"
              style={{ flex: 3 }}
              className={
                "form-control" + (errors.phoneNumber ? " is-invalid" : "")
              }
            />
          </div>
          {errors.phoneNumber && (
            <div className="invalid-feedback">
              {errors.phoneNumber?.message}
            </div>
          )}
        </div>

        <div className="mb-3">
          <input
            type="email"
            {...register("email")}
            className="form-control"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-danger">{errors.email.message}</p>
          )}
        </div>

        <div className="mb-3">
          <input
            {...register("fare")}
            className="form-control"
            placeholder="Fare (optional, automatically fetched by fare per km.)"
          />
          {errors.fare && <p className="text-danger">{errors.fare.message}</p>}
        </div>

        <div className="mb-3">
          <select
            {...register("cabType")}
            className="form-select"
            aria-label="Cab Type"
          >
            <option value="">Select Cab Type</option>
            <option value="prime">Prime</option>
            <option value="mini">Mini</option>
            <option value="micro">Micro</option>
          </select>
          {errors.cabType && (
            <p className="text-danger">{errors.cabType.message}</p>
          )}
        </div>

        {loading ? (
          <div
            className="progress"
            role="progressbar"
            aria-label="Animated striped example"
            aria-valuenow={loading}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              style={{ width: `${loading}%` }}
            ></div>
          </div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        )}
      </form>
    </>
  );
}
