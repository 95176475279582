import { Context } from "../context/store";
import { useContext, useEffect, useState } from "react";
import useModal from "../hooks/useModal";

export default function Modal() {
  const { state } = useContext(Context);
  const { title, childComponent } = state.modal;
  const { resetModal } = useModal();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (title && childComponent) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [title, childComponent]);

  return (
    <>
      {show && (
        <>
          {" "}
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title h4">{title}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => resetModal()}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">{childComponent}</div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>
      )}
    </>
  );
}
