const API_URL = "https://api.bhoogol.tech";

const CONFIG = {
  API: API_URL,
  APIS: {
    SEND_OTP: API_URL + "/auth/sendOtp",
    VERIFY_OTP: API_URL + "/auth/verifyOtp",
    REFRESH_TOKEN: API_URL + "/auth/refreshToken",
    LIVE_BOOKINGS: API_URL + "/ts/bookings/updates",
    ACCEPT_BOOKING: API_URL + "/ts/booking/accept",
    GET_CABS: API_URL + "/ts/cabs",
    ASSIGN_CAB: API_URL + "/ts/booking/assignCab",
    GET_COMPLETED_BOOKINGS: API_URL + "/ts/bookings/completed",
    GET_ONGOING_BOOKINGS: API_URL + "/ts/bookings/ongoing",
    ONBOARD_DRIVER: API_URL + "/ts/onboardDriver",
    ONBOARD_CAB: API_URL + "/ts/onboardCab",
    GET_PROFILE: API_URL + "/ts/profile",
    REQUEST_BOOKING: API_URL + "/ts/booking/request",
    GET_PENDING_DETAILS: API_URL + "/ts/bookings/pending",
    GET_INPROGRESS_BOOKINGS: API_URL + "/ts/bookings/in-progress",
    GET_CONFIRMED_BOOKINGS: API_URL + "/ts/bookings/confirmed",
    GET_PAGINATED_CABS: API_URL + "/ts/cabs/paginated",
    UPDATEFARE: API_URL + "/ts/updateBookingFare",
    FARE_PER_KM: API_URL + "/ts/updateFare",
    GET_DRIVERS: API_URL + "/ts/drivers",
    GET_DRIVERS_PAGINATED: API_URL + "/ts/drivers/paginated",
    UPDATE_DRIVER_PHONE_NUMBER: API_URL + "/ts/updateDriverDetails",
    ASSIGN_NEW_DRIVER: API_URL + "/ts/assignDriverToCab",
    UNASSIGN_DRIVER_FROM_CAB: API_URL + "/ts/unassignDriverFromCab",
  },
  STATUS_COLORS: {
    Confirmed: "grey",
    "Checked-In": "blue",
  },
  CAB_COLORS: {
    prime: "#7CB9E8",
    mini: "#007FFF",
    micro: "#00308F",
  },
  STATUS_DESCRIPTION: {
    Pending: "Ride Received",
    "In Progress": "Ride Accepted",
    Confirmed: "Driver Assigned",
    "Checked-In": "Ride Started",
    Completed: "Ride Completed",
  },
  BOOKING_CUSTOMER_LINK: "https://l.bhoogol.tech",
};

export default CONFIG;
