export default function Pagination({ page, count, offset, changePage }) {
  const totalPagesArr = [...Array(Math.ceil(count / offset))];

  function pagination(c, m) {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }

  let finalArr = pagination(page, totalPagesArr.length);

  return (
    <>
      {count && (
        <nav>
          <ul className="pagination pointy">
            <li className={"page-item" + (page === 0 ? " disabled" : "")}>
              <span className="page-link" onClick={() => changePage(page - 1)}>
                Previous
              </span>
            </li>
            {count &&
              finalArr.map((p, i) => {
                return (
                  <li
                    className={
                      "page-item" +
                      (p === "..." ? " disabled" : "") +
                      (p - 1 === page ? " active" : "")
                    }
                    test={p}
                    key={i}
                  >
                    <div
                      className="page-link"
                      onClick={() => changePage(p - 1)}
                    >
                      {p}
                    </div>
                  </li>
                );
              })}
            <li
              className={
                "page-item" +
                (page === Math.ceil(count / offset) - 1 ? " disabled" : "")
              }
            >
              <span className="page-link" onClick={() => changePage(page + 1)}>
                Next
              </span>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}
