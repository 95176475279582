import { useContext } from "react";
import { Context } from "../context/store";

export default function useForceUpdate() {
  const { forceUpdateIndicator, forceUpdate } = useContext(Context);

  return {
    forceUpdate,
    forceUpdateIndicator,
  };
}
