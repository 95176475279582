import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "../hooks/useModal";
import useAuth from "../hooks/useAuth";
import CONFIG from "../config";
import useToast from "../hooks/useToast";
import Loader from "../components/loader";
import Pagination from "../components/pagination";
import useForceUpdate from "../hooks/useForceUpdate";
import NewDriver from "../components/newDriverForm";

export default function Drivers() {
  const { setSuccess, setWarning } = useToast();
  const { forceUpdate } = useForceUpdate();

  const { setModal } = useModal();
  const { makeRequestWithAuth } = useAuth();
  const [loading, setLoading] = useState(false);

  const [drivers, setDrivers] = useState({ count: null, page: 0, data: [] });
  const [editingPhoneNumber, setEditingPhoneNumber] = useState(false); //to convert edit ---> confirm ,cancel
  const [driverNumber, setDriverNumber] = useState(""); // to catch the phone number of the driver you want to edit
  const [updatedNumber, setUpdatedNumber] = useState(""); // to set the updated phone number to be sent as payload

  const { forceUpdateIndicator } = useForceUpdate();

  useEffect(() => {
    const fetchDrivers = async () => {
      setLoading(true);
      const { error, data } = await makeRequestWithAuth({
        url:
          CONFIG.APIS.GET_DRIVERS_PAGINATED +
          `?page=${drivers.page}` +
          "&" +
          `size=10`,
        method: "get",
      });
      if (error) {
        setWarning("Error in fetching drivers");
      } else {
        setDrivers({
          ...drivers,
          data: data.drivers,
          count: data.totalDrivers,
        });
      }
      setLoading(false);
    };

    fetchDrivers();
    // eslint-disable-next-line
  }, [drivers.page, forceUpdateIndicator]);

  function removeCountryCode(phoneNumber) {
    if (phoneNumber.startsWith("+91")) {
      return phoneNumber.slice(3).trim();
    }
    return phoneNumber;
  }

  const changePage = (pageNo) => {
    setDrivers({ ...drivers, page: pageNo, count: null });
  };

  const renderTableData = () => {
    return drivers.data.length
      ? drivers.data.map((driver, index) => (
          <tr key={index}>
            <td>{driver?.user?.name || "-"}</td>
            <td>
              {!editingPhoneNumber ||
              driver?.user?.phoneNumber !== driverNumber ? (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{driver?.user?.phoneNumber || "-"}</span>
                    <div className="d-flex">
                      <button
                        className="btn btn-warning"
                        onClick={() =>
                          editPhoneNumber(
                            "edit",
                            driver?.user?.phoneNumber,
                            driver?._id
                          )
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="flex-grow-1 me-2"
                    style={{ maxWidth: "300px" }}
                  >
                    <input
                      type="text"
                      placeholder="Phone number"
                      value={updatedNumber}
                      onChange={handlePhoneNumberChange}
                      className="form-control form-control-md"
                      pattern="[0-9]*"
                    />
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn btn-success me-1"
                      onClick={() =>
                        editPhoneNumber(
                          "confirm",
                          driver?.user?.phoneNumber,
                          driver?._id
                        )
                      }
                    >
                      Confirm
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => editPhoneNumber("cancel")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </td>
            <td>{driver?.licenseNumber || "-"}</td>
            <td>
              {driver?.assigned ? (
                <span className="text-success">Assigned</span>
              ) : (
                <span className="text-warning">Unassigned</span>
              )}
            </td>
          </tr>
        ))
      : null;
  };

  const handlePhoneNumberChange = (e) => {
    setUpdatedNumber(e.target.value);
  };

  const editPhoneNumber = async (choice, number = null, driverId = null) => {
    switch (choice) {
      case "edit":
        if (number) {
          let withoutCountryCodeNumber = removeCountryCode(number);
          setUpdatedNumber(withoutCountryCodeNumber);
          setEditingPhoneNumber(true);
          setDriverNumber(number);
        }
        break;

      case "confirm":
        if (number && driverId) {
          const phoneNumberPattern = /^\d{10}$/; // Regular expression for exactly 10 digits

          if (!phoneNumberPattern.test(updatedNumber)) {
            return setWarning("Invalid phone number");
          }

          const payload = {
            phoneNumber: `+91 ${updatedNumber}`,
          };

          const { error } = await makeRequestWithAuth({
            url: CONFIG.APIS.UPDATE_DRIVER_PHONE_NUMBER + `/${driverId}`,
            method: "put",
            body: JSON.stringify(payload),
          });

          if (error) {
            setWarning("Error in updating the phone number");
          } else {
            setSuccess("Phone number updated successfully");
            forceUpdate();
          }

          setEditingPhoneNumber(false);
        }
        break;

      case "cancel":
        setEditingPhoneNumber(false);
        break;

      default:
        console.warn(`Unknown choice: ${choice}`);
        break;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start mt-5">
        <button
          className="btn btn-outline-primary"
          onClick={() => setModal("New Driver", <NewDriver />)}
        >
          Onboard Driver <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      <div className="mt-5">
        {loading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Driver Name</th>
                  <th>Phone Number</th>
                  <th>License Number</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
      </div>

      {drivers.count > 5 && (
        <Pagination
          page={drivers.page}
          changePage={changePage}
          count={drivers.count}
          offset={6}
        />
      )}
    </>
  );
}
