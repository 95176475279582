import { useEffect } from "react";
import LoginForm from "../components/loginForm";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { auth } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [auth]);

  return (
    <>
      <section>
        <div className="container-fluid vh-100" style={{ marginTop: "100px" }}>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-4">
              <h2 className="text-center mb-5">Login into your account.</h2>
              {!auth ? (
                <LoginForm />
              ) : (
                <div className="alert alert-info">
                  You are already logged in.
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
