import TYPES from "./types";
import User from "../service/user";
import Notification from "../service/notifications";
import OffCanvas from "../service/offcanvas";
import Modal from "../service/modal";

export default function Reducer(state, action) {
  const { type, payload } = action;
  const user = new User();
  const notify = new Notification();
  const canvas = new OffCanvas();
  const modal = new Modal();

  switch (type) {
    case TYPES.USER.ADD_USER:
      return user.addUser(state, payload);
    case TYPES.USER.REMOVE_USER:
      return user.removeUser(state);
    case TYPES.USER.REFRESH_ACCESS_TOKEN:
      return user.refreshAccessToken(state, payload);
    case TYPES.TOAST.SET_NOTIFICATION:
      return notify.push(state, payload);
    case TYPES.TOAST.RESET:
      return notify.pop(state);
    case TYPES.OFFCANVAS.SET_OFFCANVAS:
      return canvas.set(state, payload);
    case TYPES.MODAL.SET_MODAL:
      return modal.set(state, payload);
    default:
      return state;
  }
}
