export default class User {
  addUser = (state, payload) => {
    const { name, email, accessToken, refreshToken } = payload;
    const initialState = state.user;
    const finalState = {
      ...initialState,
      name: name,
      email: email,
      accessToken: accessToken,
      refreshToken: refreshToken,
    };

    return { ...state, user: finalState };
  };

  removeUser = (state) => {
    const initialState = state.user;
    const finalState = {
      ...initialState,
      name: null,
      email: null,
      accessToken: null,
      refreshToken: null,
    };

    return { ...state, user: finalState };
  };

  refreshAccessToken = (state, payload) => {
    const initialState = state.user;
    const { accessToken, refreshToken } = payload;
    const finalState = {
      ...initialState,
      refreshToken: refreshToken,
      accessToken: accessToken,
    };
    return { ...state, user: finalState };
  };
}
