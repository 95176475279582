import { useContext } from "react";
import { Context } from "../context/store";
import TYPES from "../context/types";

export default function useOffCanvas() {
  const { dispatch } = useContext(Context);

  const setOffCanvas = (title, childComponent) => {
    dispatch({
      type: TYPES.OFFCANVAS.SET_OFFCANVAS,
      payload: {
        title,
        childComponent,
      },
    });
  };

  const resetOffCanvas = () => {
    dispatch({
      type: TYPES.OFFCANVAS.SET_OFFCANVAS,
      payload: {
        title: null,
        childComponent: null,
      },
    });
  };

  return {
    setOffCanvas,
    resetOffCanvas,
  };
}
