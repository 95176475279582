import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import useLoadScript from "../hooks/useLoadScript";

const LocationSearch = ({ setCoordinates, title }) => {
  const [address, setAddress] = useState("");
  const [activeSuggestion, setActiveSuggestion] = useState(null);
  const { isLoaded } = useLoadScript();

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const selectedCoordinates = await getLatLng(results[0]);
      setAddress(selectedAddress);
      setCoordinates({ ...selectedCoordinates, address: selectedAddress });
    } catch (error) {
      console.error("Error fetching location details", error);
    }
  };

  if (!isLoaded) return null;

  return (
    <form>
      <div className="mb-3" controlid="locationSearch">
        <label className="form-label">{title}</label>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  className: "form-control",
                  placeholder: "Search for a location",
                })}
              />
              <div className="autocomplete-dropdown-container pointy">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: `p-1 suggestion-item ${
                        index === activeSuggestion ? "bg-primary" : ""
                      }`,
                      onMouseEnter: () => setActiveSuggestion(index),
                      onMouseLeave: () => setActiveSuggestion(null),
                    })}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </form>
  );
};

export default LocationSearch;
