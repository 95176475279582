import CONFIG from "../../../config";
import useAuth from "../../../hooks/useAuth";
import useToast from "../../../hooks/useToast";
import useOffCanvas from "../../../hooks/useOffCanvas";
import Map from "../../map";
import { useState, useEffect } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import useModal from "../../../hooks/useModal";
import CopyLinkToClipBoard from "./copyBookingLink";

export default function SelectDriver({ booking }) {
  const [selectedCab, setSelectedCab] = useState(null);
  const [cabs, setCabs] = useState([]);

  const { forceUpdate } = useForceUpdate();
  const { setModal } = useModal();

  useEffect(() => {
    const getCabsAsyncCall = async () => {
      const { error, data } = await makeRequestWithAuth({
        method: "get",
        url: CONFIG.APIS.GET_CABS,
      });
      if (error || !data.cabs) {
        return setWarning("Error in fetching active cabs");
      }

      setCabs(data.cabs);
    };
    getCabsAsyncCall();
    // eslint-disable-next-line
  }, []);

  const { makeRequestWithAuth } = useAuth();
  const { resetOffCanvas } = useOffCanvas();

  const { setSuccess, setWarning } = useToast();

  const driverChange = async (e) => {
    setSelectedCab(null);
    if (e.target.value !== "") {
      setSelectedCab(cabs.filter((cab) => cab._id === e.target.value)[0]);
    }
  };

  const assignDriver = async (id) => {
    const { error, data } = await makeRequestWithAuth({
      method: "post",
      url: CONFIG.APIS.ASSIGN_CAB + "/" + id,
      body: {
        cab: selectedCab._id,
      },
    });

    console.log(63, error, data, "\n -- - --- --- ");

    if (error) {
      setWarning(data?.error || data?.message || "Error in assigning cab.");
    } else {
      setSuccess("Cab assigned successfully");
      forceUpdate();
      resetOffCanvas();
      setModal(
        "Customer booking link.",
        <CopyLinkToClipBoard bookingId={booking._id} />
      );
    }
  };

  return (
    <>
      {selectedCab && selectedCab.location.coordinates.length === 2 && (
        <Map
          markers={[
            selectedCab.location.coordinates,
            booking.pickupLocation.coordinates,
          ]}
          showEta={true}
        />
      )}
      {cabs.length > 0 ? (
        <form style={{ marginTop: "50px" }}>
          <div className="form-outline mb-2">
            <select
              name="referenceType"
              onChange={driverChange}
              id="referenceType"
              className={"form-select form-select-sm"}
            >
              <option value=""> - - </option>
              {cabs.map((cab, i) => {
                return (
                  <option key={i} value={cab._id}>
                    {cab?.driver?.user?.name || "No Driver"} {cab.make}{" "}
                    {cab.model} {cab.registrationNumber}
                  </option>
                );
              })}
            </select>
            <label className="form-label" htmlFor="customerNo">
              Available Drivers
            </label>
          </div>
        </form>
      ) : (
        <p className="h5 text-center mb-4">No cabs available on this route</p>
      )}
      <table className="table" style={{ fontSize: "14px" }}>
        <tbody>
          <tr>
            <th scope="row">Pickup Point</th>
            <td>{booking.pickupLocation.address}</td>
          </tr>
          <tr>
            <th scope="row">Drop Point</th>
            <td>{booking.dropoffLocation.address}</td>
          </tr>
          <tr>
            <th scope="row">Fare</th>
            <td>Rs. {Math.ceil(booking.fare)}</td>
          </tr>
          <tr>
            <th scope="row">Trip Distance</th>
            <td>{Math.ceil(booking.distance)} Km</td>
          </tr>
        </tbody>
      </table>
      {selectedCab && (
        <button
          className="btn btn-outline-success mt-5"
          onClick={() => assignDriver(booking._id)}
        >
          Assign Driver
        </button>
      )}
    </>
  );
}
