import { useContext } from "react";
import { Context } from "../context/store";
import TYPES from "../context/types";

export default function useToast() {
  const { dispatch } = useContext(Context);

  const setWarning = (message) => {
    dispatch({
      type: TYPES.TOAST.SET_NOTIFICATION,
      payload: {
        message: message,
        type: "error",
      },
    });
    resetToastAfterDelay();
  };

  const setSuccess = (message) => {
    dispatch({
      type: TYPES.TOAST.SET_NOTIFICATION,
      payload: {
        message: message,
        type: "success",
      },
    });
    resetToastAfterDelay();
  };

  const resetToastAfterDelay = () => {
    setTimeout(() => {
      dispatch({
        type: TYPES.TOAST.RESET,
      });
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  return {
    setSuccess,
    setWarning,
  };
}
