import React, { useState } from "react";
import config from "../config";
import useAuth from "../hooks/useAuth";
import useToast from "../hooks/useToast";
import useForceUpdate from "../hooks/useForceUpdate";
import useModal from "../hooks/useModal";

const FareKm = ({ profile }) => {
    const { makeRequestWithAuth } = useAuth();
    const [loading, setLoading] = useState(null);

    const { setSuccess, setWarning } = useToast();
    const { forceUpdate } = useForceUpdate();
    const { resetModal } = useModal();

    const [formData, setFormData] = useState({
        ...profile.taxiService.farePerKilometer
    })

    const eventHandler = (e) => {
        const prevValue = { ...formData };
        prevValue[e.target.name] = e.target.value;
        setFormData(prevValue);
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        const { error } = await makeRequestWithAuth({
            url: config.APIS.FARE_PER_KM,
            method: "put",
            body: JSON.stringify(formData)
        });

        setLoading(100);
        if (error) {
            return setWarning("Error in updating the fare");
        } else {
            setSuccess("Fare Per KM updated successfully");
            resetModal();
            forceUpdate();
            setLoading(null);
        }
    };

    return (
        <form onSubmit={submitHandler} className="p-3">
            <div className="form-group mt-3">
                <label >Fare Price for Mini Cab:</label>
                <input type="number" className="form-control mt-3" name="mini" placeholder="Enter fare price for mini cab" value={formData.mini} onChange={eventHandler} />
            </div>
            <div className="form-group mt-3">
                <label>Fare Price for Micro Cab:</label>
                <input type="number" className="form-control mt-3" name="micro" placeholder="Enter fare price for micro cab" value={formData.micro} onChange={eventHandler} />
            </div>
            <div className="form-group mt-3">
                <label >Fare Price for Prime Cab:</label>
                <input type="number" className="form-control mt-3" name="prime" placeholder="Enter fare price for prime cab" value={formData.prime} onChange={eventHandler} />
            </div>
            <button type="submit" className="btn btn-primary mt-5">
                {loading ? 'Loading...' : 'Submit'}
            </button>
        </form>
    );
};

export default FareKm;
