export default function Tab({ tabs, currentTab, setTab }) {
  const changeTab = (t) => {
    setTab(t);
  };
  return (
    <ul className="nav nav-tabs nav-fill" style={{ marginTop: "50px" }}>
      {tabs.map((t, i) => (
        <li className="nav-item pointy fw-semibold" key={i} onClick={() => changeTab(t)}>
          <span
            className={"nav-link" + (currentTab === t ? " active" : "")}
            aria-current="page"
          >
            {t}
          </span>
        </li>
      ))}
    </ul>
  );
}
