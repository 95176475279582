import { useContext } from "react";
import { Context } from "../context/store";
import CONFIG from "../config";
import makeRequest from "../utils/makeRequest";
import TYPES from "../context/types";
import useToast from "./useToast";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

export default function useAuth() {
  const { state, dispatch } = useContext(Context);
  const { setWarning } = useToast();
  const navigate = useNavigate();
  const { user } = state;
  const { accessToken: auth } = user;
  const { refreshToken: refresh } = user;

  const sendOtp = async (phoneNumber) => {
    const { error, data } = await makeRequest({
      method: "post",
      url: CONFIG.APIS.SEND_OTP,
      body: { phoneNumber },
    });

    return { error, data };
  };

  const loginUser = async (phoneNumber, enteredOTP) => {
    const { error, data } = await makeRequest({
      method: "post",
      url: CONFIG.APIS.VERIFY_OTP,
      body: { phoneNumber, enteredOTP },
    });

    if (!error) {
      dispatch({
        type: TYPES.USER.ADD_USER,
        payload: data.token,
      });
    }

    return { error, data };
  };

  const logoutUser = () => {
    dispatch({
      type: TYPES.USER.REMOVE_USER,
      payload: null,
    });
    setWarning("User logged out");
    navigate("/");
    localStorage.removeItem("user");
  };

  const refreshAccessToken = async () => {
    const { error, data } = await makeRequest({
      method: "post",
      url: CONFIG.APIS.REFRESH_TOKEN,
      body: { refreshToken: refresh },
    });

    if (error) {
      logoutUser();
    } else {
      dispatch({
        type: TYPES.USER.REFRESH_ACCESS_TOKEN,
        payload: data.token,
      });
    }

    return data.token.accessToken;
  };

  const makeRequestWithAuth = async (options) => {
    const { exp } = jwtDecode(auth);
    const isExpired = dayjs.unix(exp).diff(dayjs()) < 1;

    let token = auth;

    if (isExpired) {
      token = await refreshAccessToken();
    }

    return await makeRequest({ ...options, token });
  };

  return {
    auth,
    user,
    loginUser,
    sendOtp,
    logoutUser,
    refreshAccessToken,
    makeRequestWithAuth,
  };
}
