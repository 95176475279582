import axios from "axios";

export default async function makeRequest(options) {
  const { method, url, body, token } = options;

  let config = {
    method: method,
    url: url,
    headers: {
      "Content-Type": "application/json",
      "xxx-bgl-usr-typ": "taxiservice",
    },
  };

  if (body && method !== "get") {
    config = { ...config, data: body };
  }

  if (token) {
    config = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  }

  let response;

  try {
    let result = await axios(config);
    response = {
      error: false,
      data: result.data,
    };
  } catch (error) {
    response = { error: true, data: error.response.data };
  }

  return response;
}
