import Map from "../../map";
import Timeline from "../../timeline";
import CopyLinkToClipBoard from "./copyBookingLink";

export default function OnGoingTracking({ booking }) {
  let markers = [];

  if (booking.status === "Confirmed") {
    markers = [
      booking.cab.location.coordinates,
      booking.pickupLocation.coordinates,
    ];
  } else if (booking.status === "Checked-In") {
    markers = [
      booking.cab.location.coordinates,
      booking.dropoffLocation.coordinates,
    ];
  }

  return (
    <>
      {booking.cab.location.coordinates.length === 2 && (
        <Map markers={markers} showEta={true} />
      )}
      {booking.status === "Confirmed" && (
        <CopyLinkToClipBoard bookingId={booking._id} />
      )}
      <Timeline timeline={booking.trackings} />
    </>
  );
}
