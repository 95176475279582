import { Context } from "../context/store";
import { useContext, useEffect, useState } from "react";
import useOffCanvas from "../hooks/useOffCanvas";

export default function OffCanvas() {
  const { state } = useContext(Context);
  const { title, childComponent } = state.offcanvas;
  const { resetOffCanvas } = useOffCanvas();
  const [show, setShow] = useState({ visibility: "hidden" });

  useEffect(() => {
    if (title && childComponent) {
      setShow({ visibility: "visible" });
    } else {
      setShow({ visibility: "hidden" });
    }
  }, [title, childComponent]);

  return (
    <div
      className="offcanvas offcanvas-end show"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
      style={show}
    >
      <div className="offcanvas-header">
        <p id="offcanvasRightLabel" className="lead">
          {title}
        </p>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => resetOffCanvas()}
        ></button>
      </div>
      <div className="offcanvas-body">{childComponent}</div>
    </div>
  );
}
