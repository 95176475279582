export default function Loader() {
  return (
    <div className="vh-100" style={{ marginTop: "400px" }}>
      <div className="d-flex justify-content-center mt-5">
        <span className="spinner-grow text-primary mx-2"></span>
        <span className="spinner-grow text-secondary mx-2"></span>
        <span className="spinner-grow text-success mx-2"></span>
        <span className="spinner-grow text-danger mx-2"></span>
      </div>
    </div>
  );
}
