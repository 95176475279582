export const carsData = {
  "Maruti Suzuki": [
    { model: "Swift", cabType: "micro" },
    { model: "Dzire", cabType: "mini" },
    { model: "S-Presso", cabType: "micro" },
    { model: "Baleno", cabType: "micro" },
    { model: "Ertiga", cabType: "prime" },
  ],
  Hyundai: [
    { model: "Grand i10", cabType: "micro" },
    { model: "Xcent", cabType: "mini" },
    { model: "Aura", cabType: "mini" },
  ],
  Tata: [
    { model: "Indica", cabType: "micro" },
    { model: "Indigo", cabType: "mini" },
    { model: "Tigor", cabType: "mini" },
    { model: "Sumo", cabType: "prime" },
  ],
  Mahindra: [
    { model: "Verito", cabType: "mini" },
    { model: "KUV100", cabType: "prime" },
    { model: "Scorpio", cabType: "prime" },
    { model: "Marazzo", cabType: "prime" },
  ],
  Toyota: [
    { model: "Etios", cabType: "mini" },
    { model: "Innova", cabType: "prime" },
  ],
  Honda: [{ model: "Amaze", cabType: "mini" }],
  Ford: [{ model: "Aspire", cabType: "mini" }],
  Volkswagen: [
    { model: "Ameo", cabType: "micro" },
    { model: "Polo", cabType: "micro" },
  ],
  Renault: [
    { model: "Kwid", cabType: "micro" },
    { model: "Pulse", cabType: "micro" },
  ],
  Nissan: [{ model: "Micra", cabType: "micro" }],
  Chevrolet: [
    { model: "Beat", cabType: "micro" },
    { model: "Sail", cabType: "mini" },
  ],
  MG: [{ model: "Hector", cabType: "prime" }],
  Datsun: [{ model: "Go", cabType: "micro" }],
};
